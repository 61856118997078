import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const About = () => {
  return (
    <Layout>
      <SEO siteTitle='About' />
      <div className='h-48 overflow-hidden'>
        <StaticImage
          src='../images/about-banner.png'
          placeholder='blurred'
          alt='About page Banner'
          className='bg-cover object-cover h-full w-full'
        />
      </div>
      <div className='flex flex-col items-center px-10 py-20 md:py-40'>
        <div className='flex flex-col items-center w-full md:max-w-xl mb-32'>
          <h1 className='text-center text-3xl font-bold text-gray-700 mb-4'>
            Mission Statement
          </h1>
          <p className='text-center mb-3 leading-9 text-lg'>
            At Angel Valuation, we strive to be a leader in the commercial real
            estate industry.
          </p>
          <p className='text-center mb-3 leading-9 text-lg'>
            To accomplish this, we invest equally in technical training, local
            market knowledge, and macroeconomic research. All of this will take
            place within a culture of solid business ethics, internally and
            externally.{' '}
          </p>
        </div>
        <div className='flex flex-col items-center w-full md:max-w-xl mb-32'>
          <h1 className='text-center text-3xl font-bold text-gray-700 mb-2'>
            Meet Our Principal{' '}
          </h1>
          <StaticImage
            src='../images/principal-potrait.png'
            alt='Potrait of the Principal of Angel Valuations'
            className='rounded-lg h-52 my-6 w-48'
            placeholder='blurred'
          />
          <p className='text-center mb-3 leading-9 text-lg'>
            Ariana Arredondo is the principal and director of Angel Valuation.
            Active in the appraisal profession for over 15 years, she has a
            portfolio of appraisal work across California which includes
            commercial real estate, business valuation, and fractional
            interests.
          </p>
          <p className='text-center mb-3 leading-9 text-lg'>
            Ariana is a Designated Member of the Appraisal Institute and is a
            Certified General Real Estate Appraiser in California (License No.
            AG041897). She has held leadership positions with the Northern
            California Chapter of the Appraisal institute, the Golden Gate
            Branch chapter of the RMA, and was a member of the Fair Value
            Exchange.
          </p>
        </div>
        <div className='flex flex-col items-center w-full md:max-w-xl '>
          <h1 className='text-center text-3xl font-bold text-gray-700 mb-4'>
            Minority ownership
          </h1>
          <p className='text-center mb-3 leading-9 text-lg'>
            Angel Valuation is a minority owned company. Additionally, we are
            proudly governed by a female-majority board of directors.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
